<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
  			<el-breadcrumb-item :to="{ path: '/home/index' }">概况总览</el-breadcrumb-item>
  			<el-breadcrumb-item>修改密码</el-breadcrumb-item>
		</el-breadcrumb>
        <div class="info-box">
            <div class="middle">
                <span class="title">修改密码</span>
            </div>
            <div class="content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm authentication-form">
                    <el-form-item label="请输入原密码" prop="oldPassword" placeholder="请填写旧密码" style="width:40%">
                        <el-input show-password v-model="ruleForm.oldPassword"></el-input>
                    </el-form-item>
                    <el-form-item label="请输入新密码" prop="password1" placeholder="请填写新密码" style="width:40%">
                        <el-input show-password v-model="ruleForm.password1"></el-input>
                    </el-form-item>
                    <el-form-item label="重复新密码" prop="password2" placeholder="请再次填写新密码" style="width:40%">
                        <el-input show-password v-model="ruleForm.password2"></el-input>
                    </el-form-item>
					<div class="fixed-bottom">
					    <el-button type="primary" @click="save()">确定提交</el-button>
					</div>
                </el-form>
            </div>
        </div>
	</div>	
</template>

<script>
import { Personal } from "@/api/index";
  export default {
    data() {
      return {
        ruleForm: {
          oldPassword: '',
          password1:'',
          sec_new_password:'',
        },
        rules: {
          oldPassword: [
            { required: true, message: '请输入旧密码', trigger: 'blur' },
         
          ],
          password1: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
           
          ],
          password2: [
            { required: true, message: '请再次输入新密码', trigger: 'blur' },
            
          ],
		}
      };
    },
   created () {
	   
   },
   methods: {
	   save() {
	   	this.$refs.ruleForm.validate(async (valid) => {
	   		if (valid) {
	   			let { status, data } = await Personal.password(this.ruleForm);
	   			if (status) {
					if(data.code == 0) {
						this.$message.success('修改成功');
						localStorage.clear()
						this.$router.replace('/login')
					} else {
						this.$message.error(data.message);
					}
	   				
	   			} 
	   		}
	   	})
	   },
   },
  }
</script>

<style lang="less">
    body {
		margin: 0;
		padding: 0;
		background-color: #F0F3FA;
	}
	.el-breadcrumb{
		padding: 30px;
		.el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
			color: #999999;
			font-weight: 500;
		}
		.el-breadcrumb__item:last-child .el-breadcrumb__inner {
			color: #1493FA;
		}
    }
    .info-box {
        height: 933px;
        margin: 0 20px;
        background-color: #ffffff;
        .middle {
            height: 58px;
            background-color: #F8F8F8;
            padding-top: 15px;
            padding-left: 30px;
            box-sizing: border-box;
            .title {
                font-size: 20px;
				color: #333333;
				font-weight: 600;
				border-left: 3px solid #1493FA;
                padding-left: 10px;
                margin-top: 20px;
            }
        }
        .content {
            .authentication-form {
                margin-top: 39px;
                .el-form-item__content {
                    margin-left: 141px!important;
                }
                .el-form-item {
                    margin-left: 30px;
                }
            }
            
        }
        .fixed-bottom {
            position: fixed;
            bottom: 0;
            right: 38px;
            width: 86.5%;
            height: 75px;
            background-color: #fff;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }
</style>